module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WRI Landscapes Monitoring","short_name":"wriLM","start_url":"/","background_color":"#fff","theme_color":"#F0AB00","display":"minimal-ui","icon":"static/wri-for-favico.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"edc9081b948da26e840c2d1aa3cdf000"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["id","en"],"defaultLanguage":"id","siteUrl":"https://tlm.wri-indonesia.id","trailingSlash":"always","generateDefaultLanguagePage":false,"fallbackLanguage":"id","redirect":false,"i18nextOptions":{"fallbackLng":"id","supportedLngs":["id","en"],"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
